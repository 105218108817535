import {
  FieldValues, Path, PathValue, UseFormSetValue,
} from 'react-hook-form';
import { useEffect } from 'react';
import { ApvTransferOptions } from '../utils/constants';

interface UseResetAPVTransferOptions<T extends FieldValues> {
  contributionOption: keyof typeof ApvTransferOptions
  setValue: UseFormSetValue<T>
  contributionAmountPath: Path<T>
  transferCompanyPath: Path<T>
}

/**
 * Watch over user selection in the APVTransfer field and resets it if needed.
 */
export function useResetAPVTransfer<T extends FieldValues>(
  options: UseResetAPVTransferOptions<T>
) {
  const {
    contributionOption, contributionAmountPath, transferCompanyPath, setValue,
  } = options;

  useEffect(() => {
    // Reset `transfer company` and `initial contribution amount`
    if (contributionOption === 'NONE') {
      setValue(contributionAmountPath, 0 as PathValue<T, Path<T>>);
      setValue(transferCompanyPath, null as PathValue<T, Path<T>>);
    }
  }, [contributionOption]);
}
