import React, { MutableRefObject, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InvestmentFormValues } from './useInvestmentForm';
import { RadioField } from '../../Forms/Fields';
import { ButtonRadioField } from '../../Forms/Fields/ButtonRadioField';
import { LongevityObjectives, RecommendationObjectives } from '../../../utils/constants';
import { injectCleanedFieldToForm } from '../../../utils/forms';

const OBJECTIVE_CARD_COMPONENTS: Record<string, JSX.Element> = {
  LONGEVITY: <LongevityObjectiveCard />,
  LIQUIDITY: <LiquidityObjectiveCard />,
  INHERITANCE: <InheritanceObjectiveCard />,
};

interface ObjectivesSectionProps {
  formRef: MutableRefObject<HTMLFormElement | null>
}

export function ObjectivesSection({ formRef }: ObjectivesSectionProps) {
  const form = useFormContext<InvestmentFormValues>();
  const { objective, net_worth } = form.watch();

  const renderObjectiveCardComponent = () => (
    objective in OBJECTIVE_CARD_COMPONENTS
      ? OBJECTIVE_CARD_COMPONENTS[objective]
      : <DefaultObjectiveCard />
  );

  const handleUncertainObjective = () => {
    form.setValue('longevity_objective', '');
    form.setValue('objective', 'OTHER');

    if (formRef.current) {
      injectCleanedFieldToForm({ form: formRef.current, value: net_worth, name: 'net_worth' });
      formRef?.current?.submit();
    }
  };

  // Cleanup `longevity_objective` field on `objective` selection
  useEffect(() => {
    form.resetField('longevity_objective');
  }, [objective]);

  return (
    <section className="card-form gap-6 text-subtitle">
      <h2 className="fs-base text-reset mb-0">¿Para qué está invirtiendo?</h2>

      <p className="mb-0">
        Si el cliente tiene un objetivo específico,
        esto ayudará a categorizarlo,
        {' '}
        <span className="fw-bold">seleccionando una opción</span>
        :
      </p>

      <div className="row justify-content-center">
        <div className="col-2xl-10">
          <div className="d-flex flex-column flex-sm-row align-items-sm-center gap-6 gap-sm-9">
            <img className="img-fluid align-self-center" src="/static/img/illustrations/investment-goals.svg" width={220} alt="" />
            <ButtonRadioField<InvestmentFormValues>
              label="¿Para qué está invirtiendo?"
              visuallyHiddenLabel
              path="objective"
              options={RecommendationObjectives.asRadioOptions<InvestmentFormValues>()}
            />
          </div>
        </div>
      </div>

      {renderObjectiveCardComponent()}

      {objective === 'INHERITANCE' && (
        <p className="alert alert-info fs-sm mb-0" role="alert">
          <div>
            Si tu cliente tiene objetivos de herencia,
            será enviado directamente a la recomendación de productos.
          </div>
        </p>
      )}

      {objective && (
        <p className="mb-0">
          Si el cliente no tiene un objetivo financiero, liquidez,
          longevidad, herencia, o no lo tiene claro, haz click
          {' '}
          <button
            type="button"
            className="btn btn-link fw-bold text-reset p-0"
            onClick={handleUncertainObjective}
          >
            aquí
          </button>
        </p>
      )}

    </section>
  );
}

export function ObjectivesSectionPlaceholder() {
  return (
    <section className="card-form h-100">
      <h2 className="fs-base text-subtitle mb-0">¿Para qué está invirtiendo?</h2>
      <div className="fw-bold fs-lg text-primary text-center my-9 my-lg-auto mx-auto" style={{ maxWidth: 290 }}>
        <p className="mb-4">Primero completa los datos de tu cliente</p>
        <img className="img-fluid mb-6" src="/static/img/illustrations/checklist-woman.svg" width={200} alt="" />
        <p className="mb-0">Y luego verás tus objetivos de inversión</p>
      </div>
    </section>
  );
}

function DefaultObjectiveCard() {
  return (
    <section className="card-message bg-info-light">
      <img src="/static/img/illustrations/problem-solving.svg" width={120} alt="" />
      <div className="fs-sm">
        <h3 className="fs-base text-primary mb-4">Objetivos de esta etapa:</h3>
        <p>
          Comprender las necesidades, objetivos y aspiraciones de nuestro cliente,
          es crucial para una relación significativa y ofrecer una asesoría personalizada.
        </p>
        <p className="mb-0">
          Nuestra misión es asociar los objetivos de nuestros clientes,
          a los macro objetivos financieros (liquidez, longevidad y herencia)
          para poder alinear expectativas.
        </p>
      </div>
    </section>
  );
}

function LongevityObjectiveCard() {
  return (
    <section className="card shadow-sm">
      <div className="card-body py-5 text-subtitle">
        <h3 className="fs-base text-secondary mb-4">Objetivo de Longevidad:</h3>
        <p className="fs-sm">
          <span className="fw-bold">Asegurar que tus recursos sean suficientes</span>
          {' '}
          para asegurar o mejorar tu estilo de vida durante toda tu vida.
          Puedes tomar diferentes niveles de riesgo
        </p>
        <RadioField<InvestmentFormValues>
          label="Objetivo de longevidad"
          visuallyHiddenLabel
          path="longevity_objective"
          options={LongevityObjectives.asRadioOptions<InvestmentFormValues>()}
          optionsDirection="vertical"
        />
      </div>
    </section>
  );
}

function LiquidityObjectiveCard() {
  return (
    <section className="card shadow-sm">
      <div className="card-body py-5 text-subtitle">
        <h3 className="fs-base text-info mb-4">Objetivo de Liquidez:</h3>
        <p>
          <span className="fw-bold">Acceso inmediato a fondos</span>
          {' '}
          para cubrir necesidades y oportunidades financieras sin afectar
          las inversiones de largo plazo.
        </p>
        <p className="mb-0">
          <span className="fw-bold">Ejemplos:</span>
        </p>
        <ul className="mb-0">
          <li>Emergencias e imprevistos.</li>
          <li>Oportunidad de inversión en un negocio.</li>
        </ul>
      </div>
    </section>
  );
}

function InheritanceObjectiveCard() {
  return (
    <section className="card shadow-sm">
      <div className="card-body py-5 text-subtitle">
        <h3 className="fs-base text-primary mb-4">Objetivo de Herencia:</h3>
        <p className="fs-sm mb-0">
          <span className="fw-bold">Preservar tu legado familiar</span>
          , asegurando el bienestar de las futuras generaciones.
          El cual puede ser transmitido a tus seres queridos o incluso a causas que te importen,
          proporcionando recursos significativos para tus hijos y futuros nietos,
          marcando una verdadera diferencia en sus vidas.
        </p>
        <p className="fs-sm mb-0">
          Con un horizonte a muy largo plazo, este objetivo te permite ser más agresivo en
          tus elecciones de inversión, maximizando el impacto y la sostenibilidad de tu patrimonio.
        </p>
      </div>
    </section>
  );
}
