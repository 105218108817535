/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  FieldValues, Path, useFormContext,
} from 'react-hook-form';
import { BaseError } from '../Errors';

type ButtonRadioProps<T extends FieldValues> = {
  id: string;
  label: string | ReactElement;
  value: string;
  path: Path<T>;
  className?: string;
};

export type ButtonRadioOption<T extends FieldValues> = Omit<ButtonRadioProps<T>, 'path'>;

function ButtonRadio<T extends FieldValues>({
  id,
  label,
  path,
  value,
  className,
}: ButtonRadioProps<T>) {
  const { register } = useFormContext<T>();

  return (
    <>
      <input id={id} className="btn-check" type="radio" value={value} autoComplete="off" {...register(path)} />
      <label className={clsx('btn', className)} htmlFor={id}>
        {label}
      </label>
    </>
  );
}

type ButtonRadioFieldProps<T extends FieldValues> = {
  label: string | ReactElement;
  visuallyHiddenLabel?: boolean;
  path: Path<T>;
  options: ButtonRadioOption<T>[];
};

export function ButtonRadioField<T extends FieldValues>({
  label,
  path,
  options,
  visuallyHiddenLabel = false,
}: ButtonRadioFieldProps<T>) {
  const { getFieldState } = useFormContext<T>();
  const { error } = getFieldState(path);

  return (
    <fieldset className="w-100">
      <legend className={visuallyHiddenLabel ? 'visually-hidden' : 'fs-sm fw-bold text-primary mb-2 d-inline-block'}>
        {label}
      </legend>

      <div className="d-flex flex-column gap-5">
        {options.map(({ id, ...props }) => (
          <ButtonRadio key={`${id}-${path}`} id={`${id}-${path}`} path={path} {...props} />)
        )}
      </div>
      <BaseError message={error?.message} />
    </fieldset>
  );
}
