import React from 'react';

export function WalletMoneyIcon() {
  return (
    <svg aria-hidden="true" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.6077 8.57376C15.5712 8.57144 15.5316 8.57146 15.4905 8.57147L15.4802 8.57147H13.8683C12.5397 8.57147 11.4029 9.62551 11.4029 11C11.4029 12.3746 12.5397 13.4286 13.8683 13.4286H15.4802L15.4905 13.4286C15.5316 13.4286 15.5712 13.4287 15.6077 13.4263C16.1473 13.392 16.6246 12.9663 16.6647 12.3718C16.6674 12.3329 16.6673 12.2908 16.6673 12.2519L16.6673 12.2413V9.75878L16.6673 9.7482C16.6673 9.70926 16.6674 9.66724 16.6647 9.62825C16.6246 9.03374 16.1473 8.6081 15.6077 8.57376ZM13.7254 11.6477C14.0675 11.6477 14.3448 11.3577 14.3448 11C14.3448 10.6424 14.0675 10.3524 13.7254 10.3524C13.3834 10.3524 13.1061 10.6424 13.1061 11C13.1061 11.3577 13.3834 11.6477 13.7254 11.6477Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.4901 14.4C15.5859 14.3975 15.6584 14.487 15.6324 14.5799C15.5034 15.0413 15.2987 15.4346 14.9701 15.7657C14.4893 16.2504 13.8795 16.4655 13.1261 16.5675C12.3941 16.6667 11.4588 16.6667 10.2779 16.6667H8.92023C7.73934 16.6667 6.80399 16.6667 6.07196 16.5675C5.3186 16.4655 4.70883 16.2504 4.22796 15.7657C3.74708 15.2811 3.53367 14.6665 3.43238 13.9072C3.33397 13.1694 3.33397 12.2267 3.33398 11.0366V10.9635C3.33397 9.77334 3.33397 8.83064 3.43238 8.09286C3.53367 7.33358 3.74708 6.71902 4.22796 6.23437C4.70883 5.74972 5.3186 5.53463 6.07196 5.43255C6.80399 5.33336 7.73933 5.33336 8.92022 5.33337L10.2779 5.33337C11.4588 5.33336 12.3941 5.33336 13.1261 5.43255C13.8795 5.53463 14.4893 5.74972 14.9701 6.23437C15.2987 6.56548 15.5034 6.95873 15.6324 7.42012C15.6584 7.5131 15.5859 7.60255 15.4901 7.60004L13.8683 7.60005C12.0455 7.60005 10.439 9.0513 10.439 11C10.439 12.9488 12.0455 14.4 13.8683 14.4L15.4901 14.4ZM5.74362 7.92385C5.47746 7.92385 5.2617 8.14131 5.2617 8.40956C5.2617 8.67782 5.47746 8.89528 5.74362 8.89528H8.3139C8.58007 8.89528 8.79583 8.67782 8.79583 8.40956C8.79583 8.14131 8.58007 7.92385 8.3139 7.92385H5.74362Z" fill="currentColor" />
      <path d="M7.1851 4.68296L8.49097 3.72088C9.19228 3.20421 10.1424 3.20421 10.8437 3.72088L12.1564 4.68802C11.6075 4.66668 10.9946 4.66669 10.3226 4.66671H8.87553C8.26147 4.66669 7.69672 4.66668 7.1851 4.68296Z" fill="currentColor" />
    </svg>
  );
}
