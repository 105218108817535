import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Radio } from '../../Forms/Fields/RadioField';
import { RiskFormValues } from './useRiskForm';
import { InvestmentProfileOptions } from '../../../utils/constants';

export function InvestmentProfileTable() {
  const { setValue } = useFormContext<RiskFormValues>();

  const setProfileValue = (value: string) => setValue('profile', value);

  return (
    <div className="table-responsive my-n5">
      <table className="table-tiles table-tiles-radio">
        <thead>
          <tr>
            <td />
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Dimensión del portafolio</div>
            </th>
            <th className="table-tile text-bg-info" scope="col" colSpan={3}>
              <div className="table-tile-divider">
                Estimación de escenarios
                <div className="d-flex justify-content-around mt-1">
                  <img src="/static/img/icons/sad-circle-white.svg" width="30" height="30" alt="Pesimista" />
                  <img src="/static/img/icons/expressionless-circle-white.svg" width="30" height="30" alt="Esperado" />
                  <img src="/static/img/icons/smile-circle-white.svg" width="30" height="30" alt="Optimista" />
                </div>
              </div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Plazo min. sugerido</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              Riesgo de la inversión
            </th>
          </tr>
        </thead>
        <tbody>
          <tr onClick={() => setProfileValue(InvestmentProfileOptions.conservative)}>
            <td className="table-tile-radio">
              <Radio<RiskFormValues>
                id={`profile-${InvestmentProfileOptions.conservative}`}
                path="profile"
                value={InvestmentProfileOptions.conservative}
                aria-label="Conservador"
              />
            </td>
            <td className="table-tile text-info bg-info-middle">
              <div className="table-tile-divider">Conservador</div>
            </td>
            <td className="table-tile text-info bg-info-middle">
              <div className="table-tile-divider">0,65%</div>
            </td>
            <td className="table-tile text-info bg-info-middle">
              <div className="table-tile-divider">4,36%</div>
            </td>
            <td className="table-tile text-info bg-info-middle">
              <div className="table-tile-divider">8,07%</div>
            </td>
            <td className="table-tile text-info bg-info-middle">
              <div className="table-tile-divider">Entre 91 y 365 días</div>
            </td>
            <td className="table-tile text-info bg-info-middle">
              <div className="bg-info-light rounded-pill p-2 text-nowrap">
                Bajo
              </div>
            </td>
          </tr>
          <tr onClick={() => setProfileValue(InvestmentProfileOptions.balanced)}>
            <td className="table-tile-radio">
              <Radio<RiskFormValues>
                id={`profile-${InvestmentProfileOptions.balanced}`}
                path="profile"
                value={InvestmentProfileOptions.balanced}
                aria-label="Balanceado"
              />
            </td>
            <td className="table-tile text-warning bg-warning-middle">
              <div className="table-tile-divider">Balanceado</div>
            </td>
            <td className="table-tile text-warning bg-warning-middle">
              <div className="table-tile-divider">0,50%</div>
            </td>
            <td className="table-tile text-warning bg-warning-middle">
              <div className="table-tile-divider">6,12%</div>
            </td>
            <td className="table-tile text-warning bg-warning-middle">
              <div className="table-tile-divider">12,74%</div>
            </td>
            <td className="table-tile text-warning bg-warning-middle">
              <div className="table-tile-divider">Sobre 1 año</div>
            </td>
            <td className="table-tile text-warning bg-warning-middle">
              <div className="bg-warning-light rounded-pill p-2 text-nowrap">
                Medio - Alto
              </div>
            </td>
          </tr>
          <tr onClick={() => setProfileValue(InvestmentProfileOptions.aggressive)}>
            <td className="table-tile-radio">
              <Radio<RiskFormValues>
                id={`profile-${InvestmentProfileOptions.aggressive}`}
                path="profile"
                value={InvestmentProfileOptions.aggressive}
                aria-label="Agresivo"
              />
            </td>
            <td className="table-tile text-danger bg-danger-middle">
              <div className="table-tile-divider">Agresivo</div>
            </td>
            <td className="table-tile text-danger bg-danger-middle">
              <div className="table-tile-divider">-4,03%</div>
            </td>
            <td className="table-tile text-danger bg-danger-middle">
              <div className="table-tile-divider">7,31%</div>
            </td>
            <td className="table-tile text-danger bg-danger-middle">
              <div className="table-tile-divider">18,66%</div>
            </td>
            <td className="table-tile text-danger bg-danger-middle">
              <div className="table-tile-divider">Sobre 2 años</div>
            </td>
            <td className="table-tile text-danger bg-danger-middle">
              <div className="bg-danger-light rounded-pill p-2 text-nowrap">
                Alto
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
