import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  DatepickerField, InputField, NumericFormatField, RadioField,
} from '../../Forms/Fields';
import { InvestmentFormValues } from './useInvestmentForm';
import {
  BooleanOptions, InvestmentProfiles,
} from '../../../utils/constants';

interface ClientSectionProps {
  handleNextStep: () => void;
  showObjectivesSection?: boolean;
}

export function ClientSection({ handleNextStep, showObjectivesSection }: ClientSectionProps) {
  const form = useFormContext<InvestmentFormValues>();
  const {
    date_of_birth,
    has_children,
    net_worth,
    profile,
    has_usd_stocks,
    wants_to_decide,
  } = form.watch();
  const isFilled = [
    date_of_birth,
    has_children,
    net_worth,
    profile,
    has_usd_stocks,
    wants_to_decide,
  ].every(Boolean);

  return (
    <section className="card-form">
      <h2 className="fs-base text-subtitle mb-0">Conociendo a mi cliente</h2>

      <InputField<InvestmentFormValues>
        id="name"
        path="name"
        label="¿Cuál es tu nombre?"
        placeholder="Ingresar nombre"
      />
      <DatepickerField<InvestmentFormValues>
        id="date_of_birth_field"
        path="date_of_birth"
        label="¿Cuál es tu fecha de nacimiento?"
      />
      <RadioField<InvestmentFormValues>
        path="has_children"
        label="¿Tienes hijos?"
        options={BooleanOptions.asRadioOptions('lowercase').reverse()}
        optionsDirection="vertical"
      />
      <NumericFormatField<InvestmentFormValues>
        id="net_worth"
        path="net_worth"
        label="¿Cuánto es tu patrimonio invertible?"
        prefix="$ "
        placeholder="$"
      />
      <RadioField<InvestmentFormValues>
        path="profile"
        label={(
          <>
            ¿Cuál es tu perfil inversionista?
            {' '}
            <span className="fw-normal">Si no sabes revisa</span>
            {' '}
            <a href="/static/documents/risk-profile-survey.xlsm" download>aquí</a>
          </>
        )}
        options={InvestmentProfiles.asRadioOptions<InvestmentFormValues>()}
        optionsDirection="vertical"
      />
      <RadioField<InvestmentFormValues>
        path="has_usd_stocks"
        label="¿Tienes inversiones en dólares?"
        options={BooleanOptions.asRadioOptions('lowercase').reverse()}
        optionsDirection="vertical"
      />
      <RadioField<InvestmentFormValues>
        path="wants_to_decide"
        label="¿Quieres tener un rol activo en la administración de tus inversiones?"
        options={BooleanOptions.asRadioOptions('lowercase').reverse()}
        optionsDirection="vertical"
        helpText={(
          <div className="text-white">
            Un rol activo se refiere a clientes que  quieran
            {' '}
            <span className="fw-bold">ser parte de la gestión de su inversión</span>
            , ya que su aprobación es necesaria para
            realizar cambios en el portafolio.
          </div>
        )}
      />

      <button
        className="btn btn-secondary py-3"
        type={showObjectivesSection ? 'button' : 'submit'}
        disabled={!isFilled || form.formState.isValidating}
        onClick={showObjectivesSection ? undefined : handleNextStep}
      >
        {showObjectivesSection ? 'Actualizar' : 'Siguiente paso'}
      </button>
    </section>
  );
}
