import React from 'react';
import { FieldValues, Path, UseFormSetValue } from 'react-hook-form';
import { ApvTransferOptions, APVTransfer } from '../../../utils/constants';
import {
  NumericFormatField, RadioField, SelectField,
} from '../../Forms/Fields';
import { useResetAPVTransfer } from '../../../hooks/useResetAPVTransfer';
import { useTransferOptions } from '../../../hooks/useTransferOptions';
import { SelectOption } from '../../Forms/Fields/SelectField';

interface APVTransferProps<T extends FieldValues> {
  contributionOption: keyof typeof ApvTransferOptions
  setValue: UseFormSetValue<T>
  contributionAmountPath: Path<T>
  contributionOptionPath: Path<T>,
  transferCompanyPath: Path<T>
}

export function APVTransferField<T extends FieldValues>({
  contributionOption,
  contributionOptionPath,
  contributionAmountPath,
  transferCompanyPath,
  setValue,
}: APVTransferProps<T>) {
  const isTransferContribution = contributionOption === ApvTransferOptions
    .TRANSFER;

  const { transferOptions } = useTransferOptions();
  const transferOptionsForSelect = transferOptions?.map((option) => ({
    label: option,
    value: option,
  })) as SelectOption<T>[];

  useResetAPVTransfer({
    contributionAmountPath,
    contributionOption,
    setValue,
    transferCompanyPath,
  });

  return (
    <>
      <RadioField
        path={contributionOptionPath}
        label="¿Tienes APV en otra institución?"
        options={APVTransfer.asRadioOptions()}
      />

      <div className="d-flex flex-column gap-4">
        {isTransferContribution && (
          <SelectField
            id="source_transfer"
            path={transferCompanyPath}
            options={transferOptionsForSelect ?? []}
            placeholder="Selecciona una opción de traspaso"
            isSearchable
          />

        )}

        {(isTransferContribution)
            && (
              <NumericFormatField<T>
                id="contribution_amount"
                path={contributionAmountPath}
                prefix="$ "
                placeholder="$"
              />
            )}

      </div>
    </>
  );
}
