import {
  RecommendationValidatedEventDetail,
  RecommendationSuccessEventDetail,
  RECOMMENDATION_VALIDATED_EVENT,
  RECOMMENDATION_SUCCESS_EVENT,
} from '../../components/RecommenderForms/common/events';
import { ProductKind } from '../../components/RecommenderForms/common/types';

const HIDDEN_BUTTON_RECOMMENDATIONS: ProductKind[] = ['apv_insured_university', 'apv_full_flexible', 'apv_with_insurance'];
const DISABLED_BUTTON_RECOMMENDATIONS: ProductKind[] = ['apv_bi'];

window.addEventListener('DOMContentLoaded', () => {
  const navigationContinueButton = document.querySelector<HTMLButtonElement>('[data-recommender-navigation="continue"]');
  if (navigationContinueButton) {
    window.addEventListener(RECOMMENDATION_SUCCESS_EVENT, (event) => {
      const { detail } = event as CustomEvent<RecommendationSuccessEventDetail>;

      if (HIDDEN_BUTTON_RECOMMENDATIONS.includes(detail.recommendation)) {
        navigationContinueButton.classList.remove('d-none');
      } else {
        navigationContinueButton.classList.add('d-none');
      }
    });
  }

  const navigationSubmitButton = document.querySelector<HTMLButtonElement>('[data-recommender-navigation="submit"]');
  if (navigationSubmitButton) {
    window.addEventListener(RECOMMENDATION_VALIDATED_EVENT, (event) => {
      const { detail } = event as CustomEvent<RecommendationValidatedEventDetail>;

      if (!DISABLED_BUTTON_RECOMMENDATIONS.includes(detail.recommendation)) return;

      navigationSubmitButton.disabled = !detail.isValid;
    });
  }
});
