// Importing Bootstrap forces the load of the type definition for the window.bootstrap global.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Bootstrap, {
  Alert,
  Collapse,
  Modal,
  Popover,
  Tooltip,
} from 'bootstrap';

// Styles
import '../scss/main.scss';

// Vendors
import './vendors/choices';
import './vendors/glider';
import './vendors/reactPdf';

// Behaviors
import './behaviors/inputs/input-rut';
import './behaviors/inputs/input-range-linked';
import './behaviors/inputs/input-money';
import './behaviors/cms/breadcrumbs';
import './behaviors/cms/gtm-inputs';
import './behaviors/cms/table-tiles';
import './behaviors/regions';
import './behaviors/simulations/mutual-funds-variant-selection';
import './behaviors/infograhps/handleInfographDownload';
import { showNewsModal } from './behaviors/cms/newsModal';
import './behaviors/notifications/notificationsPopup';
import './behaviors/recommender/navigation';

// Utils
import { App } from './utils/app';

// Load React components that can be auto-loaded on DOMContentLoaded
import { ComponentLoader } from './component-loader';
import { ExampleComponent } from './components/example-component';
import { DocumentsUploaderWrapper, DocumentsUploaderSuccessView } from './components/ContractSignin';
import { ApvLifeWrapper } from './components/SimulationsForms/ApvLife';
import { FullFlexibleForm } from './components/SimulationsForms/FullFlexible';
import { InsuredUniversityForm } from './components/SimulationsForms/InsuredUniversity';
import { ProvisionalSavingsWrapper } from './components/SimulationsForms/ProvisionalSavings';
import { InsuranceWithSavings } from './components/SimulationsForms/InsuranceWithSavings';
import { MutualFundsTimeFrame, MutualFundsMonthlyContribution } from './components/SimulationsForms/MutualFunds';
import { LoginForm, EmailForResetPasswordForm, PasswordChangeFormWrapper } from './components/AuthForms';
import { DeriveCustomerFormWrapper, SearchDerivationFormWrapper } from './components/DeriveCustomer';
import { FilterMarketingCampaignsForm } from './components/MarketingCampaigns/FilterMarketingsCampaingsForm';
import { DownloadSimulationDocsFormWrapper } from './components/DownloadSimulationDocsForm';
import { ChatBotWrapper } from './components/ChatBot';
import {
  EducationFormWrapper,
  ProjectsFormWrapper,
  PensionFormWrapper,
  InvestmentForm,
  RiskForm,
} from './components/RecommenderForms';
import { IsnModalWrapper } from './components/IsnModal';

// Simulations forms
ComponentLoader.registerComponent('#react-example-component', ExampleComponent);
ComponentLoader.registerComponent('#contract-signing_documents-uploader', DocumentsUploaderWrapper);
ComponentLoader.registerComponent('#contract-signing_success-view', DocumentsUploaderSuccessView);
ComponentLoader.registerComponent('#apv-life-forms', ApvLifeWrapper);
ComponentLoader.registerComponent('#full-flexible-form', FullFlexibleForm);
ComponentLoader.registerComponent('#insured-university-form', InsuredUniversityForm);
ComponentLoader.registerComponent('#provisional-savings-form', ProvisionalSavingsWrapper);
ComponentLoader.registerComponent('#insurance-with-savings-form', InsuranceWithSavings);
ComponentLoader.registerComponent('#mutual-funds-monthly-contribution-form', MutualFundsMonthlyContribution);
ComponentLoader.registerComponent('#mutual-funds-time-frame-form', MutualFundsTimeFrame);

// Simulation docs form
ComponentLoader.registerComponent('#download-simulation-docs-form', DownloadSimulationDocsFormWrapper);

// Auth forms
ComponentLoader.registerComponent('#login-form-wrapper', LoginForm);
ComponentLoader.registerComponent('#email-for-password-reset-form-wrapper', EmailForResetPasswordForm);
ComponentLoader.registerComponent('#password-change-form-wrapper', PasswordChangeFormWrapper);

// Derive customer form
ComponentLoader.registerComponent('#derive-customer-form-wrapper', DeriveCustomerFormWrapper);
ComponentLoader.registerComponent('#search-derivation-form-wrapper', SearchDerivationFormWrapper);

// Marketing campaigns forms
ComponentLoader.registerComponent('#filter-marketing-campaings-form-wrapper', FilterMarketingCampaignsForm);

// Chatbot
ComponentLoader.registerComponent('#chat-bot-wrapper', ChatBotWrapper);

// Recommender forms
ComponentLoader.registerComponent('#education-recommender', EducationFormWrapper);
ComponentLoader.registerComponent('#project-recommender', ProjectsFormWrapper);
ComponentLoader.registerComponent('#pension-recommender', PensionFormWrapper);
ComponentLoader.registerComponent('#investment-recommender', InvestmentForm);
ComponentLoader.registerComponent('#risk-recommender', RiskForm);

// Isn modal
ComponentLoader.registerComponent('#isn-modal-wrapper', IsnModalWrapper);

/**
 * This runs on 'DOMContentLoaded', that means it waits for every javascript to be parsed and
 * executed and waits for stylesheets and defered external scripts. This may take a long time
 * if an external resource loads too slow (should we change this to <script defer src="...">?)
 */
window.addEventListener('DOMContentLoaded', () => {
  // Load the registered react components:
  ComponentLoader.start();

  // Init collapses
  document.querySelectorAll('.collapse').forEach((collapse) => new Collapse(collapse, { toggle: false }));

  const alerts = document.querySelectorAll('.alert');
  alerts.forEach((alert) => {
    App.Utils.highlight(alert);
  });

  setTimeout(() => {
    const mainAlerts = document.querySelectorAll('.main-alert .alert');
    mainAlerts.forEach((alert) => {
      Alert.getInstance(alert)?.close();
    });
  }, 10000);

  const tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltips.forEach((tooltip) => new Tooltip(tooltip));

  const popovers = document.querySelectorAll('[data-bs-toggle="popover"]');
  popovers.forEach((popover) => new Popover(popover));

  document.querySelectorAll('form')
    .forEach((form) => {
      form.addEventListener('submit', () => {
        const submitButtons = [...form.elements].filter((element) => (
          element.matches('[type="submit"]:not(.js-do-not-disable-on-submit)')
        ));

        // Disable buttons after submit to prevent disabling submit inputs
        // with values
        submitButtons.forEach((submitButton) => {
          // eslint-disable-next-line no-param-reassign
          (submitButton as HTMLButtonElement).disabled = true;
          App.Utils.showLoading(submitButton);
        });

        return true;
      });
    });

  // Init modals
  document.querySelectorAll('.modal').forEach((modal) => new Modal(modal));

  // eslint-disable-next-line no-void
  void (async () => {
    await showNewsModal();
  })();
});
