import React from 'react';

export function RetirementTable() {
  return (
    <div className="table-responsive my-n5">
      <table className="table-tiles table-tiles-radio">
        <thead>
          <tr>
            <td />
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Dimensión del portafolio</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Objetivo de la inversión</div>
            </th>
            <th className="table-tile text-bg-info" scope="col" colSpan={3}>
              <div className="table-tile-divider">
                Estimación de escenarios
                <div className="d-flex justify-content-around mt-1">
                  <img src="/static/img/icons/sad-circle-white.svg" width="30" height="30" alt="Pesimista" />
                  <img src="/static/img/icons/expressionless-circle-white.svg" width="30" height="30" alt="Esperado" />
                  <img src="/static/img/icons/smile-circle-white.svg" width="30" height="30" alt="Optimista" />
                </div>
              </div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              <div className="table-tile-divider">Plazo min. sugerido</div>
            </th>
            <th className="table-tile text-bg-info" scope="col">
              Riesgo de la inversión
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="table-tile-radio">
              {/* Checked by default because it does not represent any value in the form.
              This input is just decoration  */}
              <input className="form-check-input" type="radio" checked />
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">Longevidad</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider text-cetner">
                <span className="d-block">Jubilación</span>
                <span className="fw-normal fs-sm">(Años para jubilar)</span>
              </div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">5,35%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider text-secondary">7,34%</div>
            </td>
            <td className="table-tile text-subtitle">
              <div className="table-tile-divider">9,23%</div>
            </td>
            <td className="table-tile text-subtitle" colSpan={2}>
              <div className="fw-bold">Retorno anual esperado según tiempo para jubilar</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
