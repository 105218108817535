/* eslint-disable @typescript-eslint/no-floating-promises */
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { requiredString } from '../../../utils/commonSchemaValidators';
import { validateAgeMayority, validateLongevityObjective } from '../../../utils/validations';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { formatMoney } from '../../../utils/formatters';
import { injectCleanedFieldToForm } from '../../../utils/forms';

const MIN_NET_WORTH = 0;

export const investmentFormSchema = z.object({
  name: requiredString(),
  date_of_birth: requiredString()
    .refine((date_of_birth) => validateAgeMayority(date_of_birth), { message: 'El cliente debe ser mayor de edad ' }),
  has_children: requiredString(),
  net_worth: z.number({ required_error: 'Este campo es requerido' })
    .min(MIN_NET_WORTH, `El valor no debe ser menor a ${formatMoney(MIN_NET_WORTH)}`),
  profile: requiredString(),
  has_usd_stocks: requiredString(),
  wants_to_decide: requiredString(),
  objective: z.string(),
  longevity_objective: z.string({
    required_error: VALIDATION_MESSAGES.REQUIRED_FIELD,
    invalid_type_error: VALIDATION_MESSAGES.REQUIRED_FIELD,
  }),
}).refine(({ objective, longevity_objective }) => (
  validateLongevityObjective(objective, longevity_objective)
), {
  path: ['longevity_objective'],
  message: VALIDATION_MESSAGES.REQUIRED_FIELD,
});

export type InvestmentFormValues = z.infer<typeof investmentFormSchema>;

export const CLIENT_SECTION_FIELDS: Array<keyof InvestmentFormValues> = [
  'name',
  'date_of_birth',
  'has_children',
  'net_worth',
  'profile',
  'has_usd_stocks',
  'wants_to_decide',
];

export const OBJECTIVES_SECTION_FIELDS: Array<keyof InvestmentFormValues> = [
  'objective',
  'longevity_objective',
];

export function useInvestmentForm(defaultValues: InvestmentFormValues) {
  const { has_children, has_usd_stocks, wants_to_decide } = defaultValues;

  const methods = useForm<InvestmentFormValues>({
    resolver: zodResolver(investmentFormSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...defaultValues,
      has_children: has_children !== null ? String(has_children) : undefined,
      has_usd_stocks: has_usd_stocks !== null ? String(has_usd_stocks) : undefined,
      wants_to_decide: wants_to_decide !== null ? String(wants_to_decide) : undefined,
    },
  });

  const [showObjectivesSection, setShowObjectivesSection] = useState(false);

  const handleSubmit: SubmitHandler<InvestmentFormValues> = (data, event) => {
    const form = event?.target as HTMLFormElement;
    injectCleanedFieldToForm({ form, value: data.net_worth, name: 'net_worth' });
    form.submit();
  };

  const handleClientSectionNextStep = async () => {
    try {
      const isClientSectionValid = await methods.trigger(CLIENT_SECTION_FIELDS);

      if (isClientSectionValid && !showObjectivesSection) {
        setShowObjectivesSection(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const verifyFieldsOnPreload = async () => {
      const isClientSectionValid = await methods.trigger(CLIENT_SECTION_FIELDS);
      methods.clearErrors(CLIENT_SECTION_FIELDS);

      if (isClientSectionValid) {
        setShowObjectivesSection(true);
      }
    };

    verifyFieldsOnPreload();
  }, []);

  return {
    methods,
    handleSubmit,
    handleClientSectionNextStep,
    showObjectivesSection,
  };
}
