import { ProductKind } from './types';

export const RECOMMENDATION_SUCCESS_EVENT = 'recommendation.success';

export type RecommendationSuccessEventDetail = {
  recommendation: ProductKind
};

export const createRecommendationSuccessEvent = (
  recommendation: ProductKind
) => new CustomEvent<RecommendationSuccessEventDetail>(
  RECOMMENDATION_SUCCESS_EVENT, {
    detail: {
      recommendation,
    },
  });

export const RECOMMENDATION_VALIDATED_EVENT = 'recommendation.validated';

export type RecommendationValidatedEventDetail = {
  recommendation: ProductKind
  isValid: boolean
};

export const createRecommendationValidatedEvent = (
  recommendation: ProductKind,
  isValid: boolean,
) => new CustomEvent<RecommendationValidatedEventDetail>(
  RECOMMENDATION_VALIDATED_EVENT, {
    detail: {
      recommendation,
      isValid,
    },
  });
