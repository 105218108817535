import React from 'react';
import { FieldValues, Path } from 'react-hook-form';
import { z } from 'zod';
import { NumericFormatField, RadioField } from '../../Forms/Fields';
import { ExtraordinaryContribution, ExtraordinaryContributionOptions } from '../../../utils/constants';

export const extraordinaryContributionSchema = z.object({
  initial_contribution_kind: z.enum([
    ExtraordinaryContributionOptions.UNIQUE,
    ExtraordinaryContributionOptions.ANNUAL,
  ]),
  initial_contribution: z.number(),
  agreed_deposits_kind: z.enum([
    ExtraordinaryContributionOptions.UNIQUE,
    ExtraordinaryContributionOptions.ANNUAL,
  ]),
  agreed_deposits: z.number(),
});

interface ExtraordinaryContributionFieldsProps<T extends FieldValues> {
  id: string
  uniqueContributionKind: Path<T>
  uniqueContributionValue: Path<T>
  agreedDepositKind: Path<T>
  agreedDepositValue: Path<T>
}

export function ExtraordinaryContributionFields<T extends FieldValues>({
  id,
  uniqueContributionKind,
  uniqueContributionValue,
  agreedDepositKind,
  agreedDepositValue,
}: ExtraordinaryContributionFieldsProps<T>) {
  return (
    <>
      <RadioField<T>
        label="Aporte único"
        path={uniqueContributionKind}
        options={ExtraordinaryContribution.asRadioOptions()}
      />

      <NumericFormatField<T>
        id={`${id}-unique-contribution-value`}
        path={uniqueContributionValue}
        prefix="$ "
        placeholder="$"
      />

      <RadioField<T>
        label="Depósito convenido"
        path={agreedDepositKind}
        options={ExtraordinaryContribution.asRadioOptions()}
      />

      <NumericFormatField<T>
        id={`${id}-agreed-deposit-value`}
        path={agreedDepositValue}
        prefix="$ "
        placeholder="$"
      />
    </>
  );
}
